import { useTranslations } from '@pepita-react/i18n';

import { getItem, getItemParent } from './useMultiselect';

import type { ListItemProps } from '../types';

type Label = {
  label: string;
  childrenLabel: string;
};

const getDropdownLabel = (
  selected: string[],
  itemLabel: string,
  parentLabel: string
): Label => {
  let label = '';
  let childrenLabel = '';

  if (selected.length >= 2) {
    label = parentLabel;
    childrenLabel = `${selected.length}`;
  }

  if (selected.length === 1) {
    label = itemLabel;
  }

  if (selected.length === 0) {
    label = parentLabel;
  }

  return { label, childrenLabel };
};

export const useLabel = (
  options: ListItemProps[],
  selected?: string[]
): Label => {
  const { trans } = useTranslations();

  if (!selected) {
    return {
      label: trans('act_select_typology', { capitalize: true }),
      childrenLabel: '',
    };
  }

  const item: Optional<ListItemProps> = getItem(options, selected[0]);

  if (!item) return { label: '', childrenLabel: '' };

  const rootElement = getItemParent(options, item) || item;

  return getDropdownLabel(selected, item.label, rootElement.label);
};
